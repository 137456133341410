import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const is_admin = (to, from, next) => {
  if (store.getters["auth/user"].role === "admin") {
    next();
    return;
  }
  next("/admin/login");
};

const is_auth = (to, from, next) => {
  if (store.getters["auth/is_auth"]) {
    next();
    return;
  }
  next("/");
};

const routes = [
  {
    path: "/",
    name: "main",
    meta: { layout: "main-layout" },
    component: () => import("../views/main-view"),
  },
  {
    path: "/catalog",
    name: "catalog",
    meta: { layout: "main-layout" },
    component: () => import("../views/catalog-view"),
  },
  {
    path: "/contribution",
    name: "contribution",
    meta: { layout: "main-layout" },
    component: () => import("../views/contribution-view"),
  },
  {
    path: "/about-production",
    name: "about-production",
    meta: { layout: "main-layout" },
    component: () => import("../views/about-production"),
  },
  {
    path: "/about-form",
    name: "about-form",
    meta: { layout: "main-layout" },
    component: () => import("../views/about-form"),
  },
  {
    path: "/faq",
    name: "faq",
    meta: { layout: "main-layout" },
    component: () => import("../views/faq"),
  },
  // {
  //   path: "/fermented-products",
  //   name: "fermented-products",
  //   meta: { layout: "main-layout" },
  //   component: () => import("../views/fermented-products"),
  // },
  // {
  //   path: "/fermentum-products",
  //   name: "fermentum-products",
  //   meta: { layout: "main-layout" },
  //   component: () => import("../views/fermentum-products"),
  // },
  // {
  //   path: "/fresh-herbal-products",
  //   name: "fresh-herbal-products",
  //   meta: { layout: "main-layout" },
  //   component: () => import("../views/fresh-herbal-products"),
  // },
  // {
  //   path: "/probiotic-enzyme-cosmetics",
  //   name: "probiotic-enzyme-cosmetics",
  //   meta: { layout: "main-layout" },
  //   component: () => import("../views/probiotic-enzyme-cosmetics"),
  // },
  // {
  //   path: "/equipment",
  //   name: "equipment",
  //   meta: { layout: "main-layout" },
  //   component: () => import("../views/equipment-view"),
  // },
  // {
  //   path: "/animal-products",
  //   name: "animal-products",
  //   meta: { layout: "main-layout" },
  //   component: () => import("../views/animal-products"),
  // },
  {
    path: "/declarations-and-certificates",
    name: "declarations-and-certificates",
    meta: { layout: "main-layout" },
    component: () => import("../views/declarations-and-certificates"),
  },
  {
    path: "/mpo-documents",
    name: "mpo-documents",
    meta: { layout: "main-layout" },
    component: () => import("../views/mpo-documents"),
  },
  {
    path: "/recipes",
    name: "recipes",
    meta: { layout: "main-layout" },
    component: () => import("../views/recipes"),
  },
  {
    path: "/reviews",
    name: "reviews",
    meta: { layout: "main-layout" },
    component: () => import("../views/reviews"),
  },
  {
    path: "/target-program",
    name: "target-program",
    meta: { layout: "main-layout" },
    component: () => import("../views/target-program"),
  },
  {
    path: "/contact",
    name: "contact",
    meta: { layout: "main-layout" },
    component: () => import("../views/contact"),
  },
  {
    path: "/catalog/item/:product",
    name: "product",
    meta: { layout: "main-layout" },
    component: () => import("../views/product-view"),
  },
  {
    path: "/profile",
    name: "profile",
    beforeEnter: is_auth,
    meta: { layout: "main-layout" },
    component: () => import("../views/profile"),
    children: [
      {
        path: "user",
        name: "profile-user",
        meta: { layout: "main-layout" },
        component: () => import("../views/profile/user"),
      },
      {
        path: "orders",
        name: "profile-orders",
        meta: { layout: "main-layout" },
        component: () => import("../views/profile/orders"),
      },
      {
        path: "letters",
        name: "profile-letters",
        meta: { layout: "main-layout" },
        component: () => import("../views/profile/letters"),
      },
      {
        path: "letters/item/:id",
        name: "profile-letter-item",
        meta: { layout: "main-layout" },
        component: () => import("../views/profile/letter-item"),
      },
    ],
  },
  {
    path: "/cart",
    name: "cart",
    meta: { layout: "main-layout" },
    component: () => import("../views/cart"),
  },
  {
    path: "/cart/order",
    name: "order",
    meta: { layout: "main-layout" },
    component: () => import("../views/cart/order"),
  },
  {
    path: "/admin",
    name: "admin",
    meta: { layout: "admin-layout" },
    component: () => import("../views/admin"),
    children: [
      {
        path: "login",
        name: "admin-login",
        meta: { layout: "admin-layout" },
        component: () => import("../views/admin/admin-login"),
      },
      {
        path: "store",
        name: "admin-store",
        beforeEnter: is_admin,
        meta: { layout: "admin-layout" },
        component: () => import("../views/admin/admin-store"),
      },
      {
        path: "store/item/:product",
        name: "admin-product-editor",
        beforeEnter: is_admin,
        meta: { layout: "admin-layout" },
        component: () =>
          import("../views/admin/admin-store/admin-product-editor"),
      },
      {
        path: "orders",
        name: "admin-orders",
        beforeEnter: is_admin,
        meta: { layout: "admin-layout" },
        component: () => import("../views/admin/admin-orders"),
      },
      {
        path: "mail",
        name: "admin-mail",
        beforeEnter: is_admin,
        meta: { layout: "admin-layout" },
        component: () => import("../views/admin/admin-mail"),
      },
      {
        path: "mail/new-message",
        name: "new-message",
        beforeEnter: is_admin,
        meta: { layout: "admin-layout" },
        component: () => import("../views/admin/admin-mail/new-message"),
      },
      {
        path: "management",
        name: "admin-management",
        beforeEnter: is_admin,
        meta: { layout: "admin-layout" },
        component: () => import("../views/admin/admin-management"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
