<template>
  <div id="app">
    <component :is="layout" />
  </div>
</template>

<script>
import MainLayout from "@/layouts/main-layout";
import AdminLayout from "@/layouts/admin-layout";
import { mapGetters } from "vuex";
import notifications from "@/mixins/notifications";
import VIcon from "@/components/icon/v-icon.vue";

export default {
  components: { VIcon, AdminLayout, MainLayout },
  mixins: [notifications],

  data: () => ({
    is_loading: true,
  }),

  computed: {
    ...mapGetters({
      width_page: "utils/width_page",
      is_auth: "auth/is_auth",
      user: "auth/user",
      cart: "cart/cart",
    }),

    layout() {
      return this.$route.meta.layout;
    },

    isLoading() {
        return this.layout
    }
  },

  beforeCreate() {
    this.is_loading = true;
  },

  watch: {
    isLoading: {
      handler() {
        var id = 'json-ld-generated';
        var script = document.getElementById(id);
        const elem = document.createElement('script');
        elem.type = 'application/ld+json';
        elem.id = id;
        elem.text = "";
        if (script != null) {
            script.remove();
        }
      },
      deep: true,
    },
  },

  mounted() {
    window.addEventListener("resize", this.updateWidthPage);
    this.$store.commit("utils/UPDATE_WIDTH_PAGE", window.innerWidth);

    window.addEventListener("scroll", this.updateScrollPosition);
    this.$store.commit("utils/UPDATE_SCROLL_POSITION", window.scrollY);

    this.initPage();
  },

  methods: {
    async initPage() {
      try {
        if (this.is_auth) {
          await this.$store.dispatch("auth/getUser");
          await this.$store.dispatch("user/getLetters");
        }

        if (this.user.cart) {
          const params = {
            cart_code: this.user.cart,
          };
          await this.$store.dispatch("cart/getCart", params);
        }

        if (this.cart && this.cart.cart_code) {
          const params = {
            cart_code: this.cart.cart_code,
          };
          await this.$store.dispatch("cart/checkCart", params);
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.is_loading = false;
      }
    },

    updateWidthPage() {
      this.$store.commit("utils/UPDATE_WIDTH_PAGE", window.innerWidth);
    },

    updateScrollPosition() {
      this.$store.commit("utils/UPDATE_SCROLL_POSITION", window.scrollY);
    },
  },
};
</script>
